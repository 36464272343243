import "./Imprint.css";

export default function Imprint() {
    return (
        <div className="imprint">
            <div className="imprint-container">

                <div>
                    <h2>Impressum</h2>

                    <p>
                        Webimpressum laut §5ECG:
                    </p>

                    <p>
                        <span className="bold">Ich bin freiberufliche DGKP und Vortragende, ich bin im Rahmen der Rechtsform "Neue Selbständige" tätig. <br/>Gesundheitsberuferegister: 19-GBR-119546</span> <br />
                    </p>
                    <p>
                        Ich bin für den Inhalt dieser Seiten verantwortlich, trotz aller Sorgfalt übernehme ich keine Garantie oder Haftung für Vollständigkeit oder Richtigkeit.  <br />
                    </p>
                    <p>
                        <span className="bold">Fotos:</span> Claudia Müllner <br />
                        <span className="bold">Schriftart:</span> <a href="http://www.onlinewebfonts.com" target="_blank" rel="noopener noreferrer"> oNline Web Fonts</a>

                    </p>
                    <p>
                        Monika Karl-Schild<br />
                        2133 Hagenberg 104<br />
                        <span className="bold">Email: </span><a href="mailto:info@mks-pflegeberatung.at">info@mks-pflegeberatung.at</a><br />
                        <span className="bold">Telefon: </span><a href="tel:+43 670 6581666">+43 670 6581666</a><br />
                        19-GBR119546<br />
                    </p>
                </div>

                <div>
                    <h2>Erklärung zur Informationspflicht</h2>

                    <h3>Datenschutzerklärung</h3>
                    <p>
                        Der Schutz Ihrer persönlichen Daten ist mir ein besonderes Anliegen. Ich verarbeite Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informiere ich Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen meiner Website.
                    </p>

                    <h3>Kontakt mit mir</h3>
                    <p>
                        Wenn Sie per E-Mail Kontakt mit mir aufnehmen oder mich über das Kontaktformular kontaktieren, werde ich die angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate gespeichert haben. Diese Daten gebe ich nicht ohne Ihre Einwilligung weiter.
                    </p>

                    <h3>Ihre Rechte</h3>
                    <p>
                        Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist dies die Datenschutzbehörde.
                    </p>
                </div>
            </div>
        </div>
    );
}
import "./CommunityNursing.css";

export default function CommunityNursing() {
	return (
		<div className="community-nursing">
			<div className="community-nursing-container">
				<p>
					Ziel ist es, die Gesundheitskompetenz der Bevölkerung zu stärken, das Wohlbefinden zu verbessern sowie
					den Verbleib von älteren Menschen im eigenen Zuhause nicht zuletzt durch Stärkung der Selbsthilfe von Betroffenen und deren Angehörigen zu ermöglichen.
				</p>

				<ul>
					<li>
						Diplomierte Gesundheits- und Krankenpflegepersonen bieten wohnortnah und niederschwellig Angebote zur Unterstützung und Entlastung an
					</li>
					<li>
						Bereits bestehende Dienstleistungen, wie die Hauskrankenpflege, werden ergänzt
					</li>
				</ul>


				<h3>Was sind die Ziele von Community Nursing?</h3>

				<ul>
					<li>
						Längerer Verbleib im eigenen Zuhause
					</li>
					<li>
						Förderung von Gesundheit, Lebensqualität, Wohlbefinden und Selbsthilfefähigkeit
					</li>
					<li>
						Förderung der Gesundheitskompetenzen
					</li>
					<li>
						Vernetzung regionaler Angebote zu Pflege und Gesundheit
					</li>
				</ul>


				<h3>Wer sind die Zielgruppen?</h3>

				<ul>
					<li>
						Ältere, zu Hause lebende Menschen
					</li>
					<li>
						Pflege- und betreuungsbedürftige Personen
					</li>
					<li>
						Pflegende und betreuende Angehörige
					</li>
				</ul>


				<h3>Was sind die Hauptaufgaben der Community Nurses?</h3>

				<ul>
					<li>
						Zentrale Anlaufstelle für Fragen zu Pflege und Gesundheit
					</li>
					<li>
						Präventive Hausbesuche
					</li>
					<li>
						Information und Beratung
					</li>
					<li>
						Erhebung der aktuellen Versorgung und ungedeckter Bedarfe
					</li>
					<li>
						Koordination und Vermittlung zusätzlicher Angebote
					</li>
				</ul>

				<p>
					Nähere Infos:&nbsp;
					<a href={"https://cn-oesterreich.at"} target="_blank" rel="noopener noreferrer">
						https://cn-oesterreich.at
					</a>
				</p>

				<p>
					<span className="bold">In Niederösterreich sind aktuell 60 Community Nurses in 26 Projekten im Einsatz.</span> <br/>
					Im Bezirk Mistelbach gibt es in der Stadtgemeinde Laa/Thaya 1 Community Nurse,
					weiters in Stadtgemeinde Hollabrunn, Deutsch&nbsp;Wagram, Marchfeld und Klosterneuburg.
				</p>

				<a href={"https://maps.goeg.at/community-nursing"} target="_blank" rel="noopener noreferrer">
					https://maps.goeg.at/community-nursing
				</a>

				<p>
					Diese sind für 2025 gesichert, was 2026 weiter geplant ist, kann noch nicht gesagt werden.
				</p>
			</div>
		</div>
	);
}

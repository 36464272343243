import "./Header.css";

import { useImperativeHandle, useState, forwardRef } from "react";

import { Link } from "react-router-dom";

import Hamburger from "../Hamburger/Hamburger";

import logo from "../../Media/logo.jpg";

const Header = forwardRef((props, ref) => {
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburgerOpen = () => {
        setHamburgerOpen(!hamburgerOpen);
    }

    const backDropHandler = () => {
        setHamburgerOpen(false);
    }

    useImperativeHandle(ref, () => ({
        closeHamburger() {
            setHamburgerOpen(false);
        }
    }));

    return (
        <div className="headerContainer">
            <div className="header">
                <div className="logoContainer">
                    <Link className="logoLink" to={"/"}>
                        <img className="logo" src={logo}/>
                    </Link>
                </div>

                <navbar className="nav-link-container">
                    <Link className="navLink" to={"/about"}>Über mich</Link>
                    <Link className="navLink" to={"/explanation"}>Was ist Pflegeberatung?</Link>
					<Link className="navLink" to={"/communitynursing"} translate="no">Community Nursing</Link>
                    <Link className="navLink" to={"/services"}>Leistungen</Link>
                    <Link className="navLink" to={"/contact"}>Kontakt</Link>
                    <Link className="navLink" to={"/imprint"}>Impressum</Link>
                </navbar>

                <div className={`hamburgerContainer ${hamburgerOpen ? "open" : ""}`} onClick={toggleHamburgerOpen}>
                    <Hamburger />
                </div>
            </div>

            <div
                className={`backDrop ${hamburgerOpen ? "open" : ""}`}
                onClick={backDropHandler}
            />

            <navbar className={`sideDrawerNav ${hamburgerOpen ? "open" : ""}`} >
                <Link className="sideDrawerNavLink" to={"/about"}>Über mich</Link>
                <Link className="sideDrawerNavLink" to={"/explanation"}>Was ist Pflegeberatung?</Link>
				<Link className="sideDrawerNavLink" to={"/communitynursing"} translate="no">Community Nursing</Link>
                <Link className="sideDrawerNavLink" to={"/services"}>Leistungen</Link>
                <Link className="sideDrawerNavLink" to={"/contact"}>Kontakt</Link>
                <Link className="sideDrawerNavLink" to={"/imprint"}>Impressum</Link>
            </navbar>
        </div>
    );
});

export default Header;

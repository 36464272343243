import "./About.css";

import about1 from "../../Media/about/about1.jpg";

export default function About() {
    return (
        <div className="about">
            <div className="aboutContainer">
                <div className="aboutPictureContainer">
                    <div>
                        <img src={about1} />
                    </div>

                    <div>
                        <p>
                            Mein Name ist Monika Karl-Schild, ich bin 1972 geboren -
                            verheiratet und habe 3 Söhne. <br />
                            Seit 1993 bin ich Dipl. Gesundheits- u Krankenpflegerin. <br />
                            Meine Berufserfahrung habe ich in der Hospiz- u. Palliativarbeit sowie in der Arbeit mit alten Menschen mit Demenzerkrankung gemacht.<br />
                            Mein Schwerpunkt in der Beratung ist die Demenz und Palliative Geriatrie:
                        </p>
                    </div>
                </div>

                <div className="aboutTextContainer">
                    <p>
                        Gut alt werden und in der Phase der Veränderung unterstützend da sein! <br />
                        Mir ist wichtig, eine andere Sichtweise - Perspektivenwechsel - in die Betreuung einfließen zu lassen: die Würde des alten Menschen wahren und dass wir Betreuer unsere Denkweise erweitern, so dass wir mit dem veränderten Verhalten besser umgehen können. <br />
                        Dabei ist es manchmal notwendig, Wesentliches anzusprechen, das was gerade da ist. <br />
                        In empathischer Haltung möchte ich mit Ihnen den Prozess der Veränderung begleiten. <br />
                        Das Gespräch soll zur Verbesserung und Entlastung der Situation beitragen. <br />
                    </p>
                    <p>Motivierend - Kreativ - Selbstbestimmt</p>
                </div>

                <div className="aboutListContainer">
                    <h2>Berufsweg</h2>
                    <ul>
                        <li>
                            <span className="bold">DGKP - Palliativteam</span><br />
                            LK Mistelbach, 2130 Mistelbach
                        </li>
                        <li>
                            <span className="bold">Hospizkoordinatorin</span><br />
                            Caritas d. Erzdiözese Wien, Mobiles Hospiz
                        </li>
                        <li>
                            <span className="bold">DGKP - Hauskrankenpflege</span><br />
                            Caritas Mobile Dienste, 2061 Untermarkersdorf
                        </li>
                        <li><span className="bold">DGKP - Hospizstation</span><br />
                            CS Pflege u Sozialzentrum Rennweg, 1030 Wien
                        </li>
                        <li><span className="bold">DGKP - Anästhesie-Intensivstation</span><br />
                            Krankenhaus der Barmherzigen Brüder, 1020 Wien
                        </li>
                        <li>
                            <span className="bold">DGKP - Anästhesie Intensiv-Station</span><br />
                            SMZ-Ost, 1220 Wien
                        </li>
                    </ul>

                    <div className="videoContainer">
                        <iframe src="https://www.youtube.com/embed/iVsQfJQgD5Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"></iframe>

                        <p>Filmaufnahme im Herbst 2020</p>
                    </div>

                    <h2>Zusatzqualifikationen</h2>
                    <ul>
						<li>Lebenslang Musikbegeleiter 2024</li>
                        <li>Dipl. Aromaberaterin  </li>
                        <li>Letzte Hilfe-Kursleiterausbildung</li>
                        <li>MAS Demenztrainer-Ausbildung</li>
                        <li>Ethikseminar zur Implementierung einer klinischen Ethikberatung</li>
                        <li>Grundkurs „Kinästhetics in der Pflege“</li>
                        <li>
                            Universitätslehrgang „Palliative- und Dementia Care“
                            <br />
                            Alte Menschen und Sorgekultur am IFF (Wien-Berlin)
                        </li>
                        <li>Ausbildung zum Validationsanwender (Level 1)</li>
                        <li>
                            „Palliative Care“ (Palliativ- & Hospizpflege)
                            <br />
                            NÖ Landesakademie, Mödling gem. § 64 des GuKG, BGBI
                        </li>
                        <li>Basale Stimulation - Basis- & Aufbaukurs,&nbsp;in Palliativausbildung</li>
                        <li>Einführungskurs f. Lebens- Sterbe- & Trauerbegleitung </li>
                    </ul>

                    <h2>Mitglied von</h2>
                    <ul>
                        <li>ÖGKV - Österreichischem Gesundheits- und Krankenpflegeverband</li>
                        <li>FGPG - Fachgesellschaft für Palliative Geriatrie</li>
                        <li>Landesverband Hospiz NÖ</li>
                        <li>Botschafterin von „Merk i ma“ - Demenz einfach erklärt</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}